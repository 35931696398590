import React from "react";
import memoji from "../../img/memoji.webp";

type Props = {};

const Home = (props: Props) => {
  return (
    <div className="flex flex-col object-scale-down items-center min-h-screen gap-12">
      <div className="flex flex-col md:flex-row text-6xl justify-center items-center font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 gap-2">
        <div>Alexander</div>
        <div>Julius</div>
        <div>Klemt</div>
      </div>
      <div className="flex flex-row text-xl gap-6">
        React JS <div>·</div> SwiftUI
      </div>
      <img src={memoji} alt="" className="w-1/2" width="100%" height="100%" />
    </div>
  );
};

export default Home;
